<template>
  <div class="">
    <WaBtn />
    <TheHeader/>
    <TheBanner/>

    <!-- Services -->
    <div class="bg-repeat" style="background-image: url(../images/bg-seam.png)">
      <div class="container py-6 mx-auto">
        <h1 class="pb-6 text-2xl text-center text-gray-600 md:text-4xl poppin-semi">Our Services</h1>
        <h2 class="pb-2 text-3xl text-center text-green-700 open-bold">Carpet</h2>
        <div class="flex flex-wrap justify-center open-reg">
          <div class="w-1/2 px-2 md:w-1/3 lg:w-1/4" v-for="(item, i) in carPet" :key="i"> 
            <br>
            <img :src="item.image" :alt="item.alt" class="mx-auto">
          </div>
        </div>

        <h2 class="pt-8 pb-2 text-3xl text-center text-green-700 open-bold">Laminate Flooring</h2>
        <div class="flex flex-wrap justify-center open-reg">
          <div class="w-1/2 px-2 md:w-1/3 lg:w-1/4" v-for="(item, i) in lamiNate" :key="i"> 
            <br>
            <img :src="item.image" :alt="item.alt" class="mx-auto">
          </div>
        </div>

        <h2 class="pt-8 pb-2 text-3xl text-center text-green-700 open-bold">SPC Flooring</h2>
        <div class="flex flex-wrap justify-center open-reg">
          <div class="w-1/2 px-2 md:w-1/3 lg:w-1/4" v-for="(item, i) in sPc" :key="i"> 
            <br>
            <img :src="item.image" :alt="item.alt" class="mx-auto">
          </div>
        </div>

        <h2 class="pt-8 pb-2 text-3xl text-center text-green-700 open-bold">Vinyl Flooring</h2>
        <div class="flex flex-wrap justify-center open-reg">
          <div class="w-1/2 px-2 md:w-1/3 lg:w-1/4" v-for="(item, i) in vinYl" :key="i"> 
            <br>
            <img :src="item.image" :alt="item.alt" class="mx-auto">
          </div>
        </div>

        <h2 class="pt-8 pb-2 text-3xl text-center text-green-700 open-bold">Solid Wood Flooring</h2>
        <div class="flex flex-wrap justify-center open-reg">
          <div class="w-1/2 px-2 md:w-1/3 lg:w-1/4" v-for="(item, i) in woOd" :key="i"> 
            <br>
            <img :src="item.image" :alt="item.alt" class="mx-auto">
          </div>
        </div>
      </div>
    </div>

    <!-- About Us -->
    <div>
      <div class="flex flex-wrap justify-center">
        <div class="w-full py-32 bg-right-bottom bg-no-repeat bg-cover lg:bg-bottom lg:w-1/2 lg:py-0" style="background-image: url(../images/about-us.jpg)"></div>
        <div class="w-full px-4 py-6 lg:w-1/2">
          <h1 class="pb-3 text-2xl text-center text-gray-600 lg:text-left md:text-4xl poppin-semi">About Us</h1>
          <p class="text-sm text-gray-700 lg:text-base open-reg">
            <b>KTWO TRADING</b> is Malaysia’s leading provider of quality <b>Vinyl Flooring, Laminate Flooring, SPC Flooring & Carpet</b> solutions. 
            With over 20 years of experience, we continue to provide premium flooring for homes or work spaces.
            <br><br>
            Customer’s Satisfaction is our main priority. We strongly believe in giving the best price whilst delivering the best quality 
            and service.
            <br><br>
            Free Quotation, Site Visit, Measurement & Sample Viewing!
          </p>
        </div>
      </div>
    </div>

    <!-- Why Choose Us -->
    <div class="">
      <div class="container py-6 mx-auto text-center ">
        <h1 class="pb-6 text-2xl text-center text-gray-600 md:text-4xl poppin-semi">Why Choose Us</h1>
        <div class="flex flex-wrap justify-center">
          <div class="w-1/2 px-3 md:w-1/4" v-for="(item, i) in chooseUs" :key="i">
            <img :src="item.image" :alt="item.alt" class="mx-auto">
            <p class="pt-4 pb-6 text-gray-800 open-reg">{{ item.alt }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- testi -->
    <div class="bg-gray-200 ">
      <div class="container py-6 mx-auto">
        <h1 class="pb-6 text-2xl text-center text-gray-600 md:text-4xl poppin-semi">Testimonials</h1>
        <carousel :autoplay="false" :loop="true" :autoplayHoverPause="false" :autoplayTimeout="2500" :perPage="1">
          <slide>
            <div class="text-center">
              <p class="text-gray-700 open-reg">- Very good attitude and professional service! -</p>
              <h1 class="pt-4 poppin-bold">Cynthia</h1>
            </div>
          </slide>
          <slide>
            <div class="text-center">
              <p class="text-gray-700 open-reg">- Thanks for the excellent service, they making my house absolutely amazing with 
                laminate flooring. -</p>
              <h1 class="pt-4 poppin-bold">Bavani</h1>
            </div>
          </slide>
          <slide>
            <div class="text-center">
              <p class="text-gray-700 open-reg">- The team are professional and friendly, they even teach me how to take care of 
                my vinyl flooring after installed, highly recommended! -</p>
              <h1 class="pt-4 poppin-bold">Shima</h1>
            </div>
          </slide>
        </carousel>
      </div>
    </div>

    <!-- Enquiry Form -->
    <div class="py-6 bg-repeat" style="background-image: url(../images/bg-seam.png)">
      <div class="container mx-auto text-center">
        <h1 class="pb-6 text-2xl text-center text-gray-600 md:text-4xl poppin-semi" id="element">Send An Enquiry </h1>
        <div class="px-5">
          <p class="pb-3 text-gray-700 open-reg">Fill out the form below and we'll get back to you as soon as possible</p>
        </div>
        <!-- feedback.activamedia.com.sg script begins here -->
        <iframe allowTransparency="true" style="min-height:520px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5835323.html"></iframe>
        <!-- feedback.activamedia.com.sg script ends here -->
      </div>
    </div>

    <!-- Footer -->
    <div class="py-6">
      <div class="container mx-auto">
        <div class="justify-center md:flex md:flex-wrap">
          <div class="w-full pb-4 text-center text-gray-700 md:w-1/2 md:text-left md:pb-0 md:pl-4">
            <p class="pb-2 text-xl poppin-bold">KTWO Trading</p>
            <p class="pb-2 open-semi">
              NO. J110, Jalan Perkasa 8, <br> Salak South, 57100 Kuala Lumpur. 
            </p>
            <a href="tel:+60122363652">Tel : 012-2363652</a>
          </div>
          <div class="w-full md:w-1/2">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0687927000517!2d101.6906669151427!3d3.0763044977598066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4a630bbdcf83%3A0xf5d79534a61a1108!2sj110%2C%20Jalan%20Perkasa%208%2C%20Salak%20South%20Garden%2C%2057100%20Kuala%20Lumpur%2C%20Wilayah%20Persekutuan%20Kuala%20Lumpur!5e0!3m2!1sen!2smy!4v1614675137785!5m2!1sen!2smy" class="mx-auto" width="70%" height="auto" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </div>

    <!-- copyright -->
    <Thecopyright/>

  </div>
</template>

<script>
// @ is an alias to /src
import TheHeader from '@/components/TheHeader.vue'
import TheBanner from '@/components/TheBanner.vue'
import Thecopyright from "@/components/Thecopyright.vue";
import WaBtn from "@/components/WaBtn.vue"

export default {
  name: 'Home',
  components: {
    TheHeader,
    TheBanner,
    Thecopyright,
    WaBtn
  },
  data(){
    return{
      carPet:[
        {image: "images/service-01.jpg", alt: "Carpet"},
        {image: "images/service-02.jpg", alt: "Carpet"},
        {image: "images/service-03.jpg", alt: "Carpet"},
      ],
      lamiNate:[
        {image: "images/service-04.jpg", alt: "Laminate Flooring"},
        {image: "images/service-05.jpg", alt: "Laminate Flooring"},
        {image: "images/service-06.jpg", alt: "Laminate Flooring"},
        {image: "images/service-07.jpg", alt: "Laminate Flooring"},
      ],
      sPc:[
        {image: "images/service-08.jpg", alt: "SPC Flooring"},
        {image: "images/service-09.jpg", alt: "SPC Flooring"},
        {image: "images/service-10.jpg", alt: "SPC Flooring"},
      ],
      vinYl:[
        {image: "images/service-11.jpg", alt: "Vinyl Flooring"},
        {image: "images/service-12.jpg", alt: "Vinyl Flooring"},
        {image: "images/service-13.jpg", alt: "Vinyl Flooring"},
      ],
      woOd:[
        {image: "images/service-14.jpg", alt: "Solid Wood Flooring"},
        {image: "images/service-15.jpg", alt: "Solid Wood Flooring"},
        {image: "images/service-16.jpg", alt: "Solid Wood Flooring"},
        {image: "images/service-17.jpg", alt: "Solid Wood Flooring"},
      ],
      chooseUs:[
          {
            image: "images/choose-1.png",
            alt: "Over 20 Years of Experience",
          },
          {
            image: "images/choose-2.png",
            alt: "Expert Installer",
          },
          {
            image: "images/choose-3.png",
            alt: "Quality Products",
          },
          {
            image: "images/choose-4.png",
            alt: "99% Customer Satisfaction",
          },
        ],
    }
  }
}
</script>

<style>
/* Font */
.poppin-semi {font-family: "Poppins", sans-serif; font-weight: 600;}
.poppin-bold {font-family: "Poppins", sans-serif; font-weight: 700;}

.open-reg {font-family: "Open Sans", sans-serif; font-weight: 400;}
.open-semi {font-family: "Open Sans", sans-serif; font-weight: 600;}
.open-bold {font-family: "Open Sans", sans-serif; font-weight: 700;}

/* Max-width */
.maxWidth{max-width: 1920px;}
</style>
