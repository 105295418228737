<template>
    <div>
        <div class="py-32 bg-bottom bg-no-repeat bg-cover" style="background-image: url(../images/banner.jpg)">
            <div class="container mx-auto">
                <div class="">
                    <h1 class="text-2xl text-center text-white lg:text-5xl poppin-bold textShadow"> 
                        Do You Want To Get Your Flooring <br> Done With Better Quality & Cost?
                    </h1>
                    <a v-if="!hideEnquiry" href="#" class="block w-64 py-3 mx-auto mt-10 text-lg text-center text-white bg-gray-700 rounded-lg poppin-semi animate-pulse" v-scroll-to="{el:'#element',duration: 1500,}">ENQUIRE NOW</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

export default {
    props: {
        hideEnquiry: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>
/* Shadow */
.textShadow{text-shadow: 2px 2px 2px #000;}
</style>
