<template>
    <div class="container py-8 mx-auto md:py-5 lg:py-3">
        <div class="items-center justify-center px-6 sm:flex sm:flex-wrap sm:justify-between">
            <div class="w-full md:w-1/3">
                <!-- <img src="/images/logo.jpg" alt="KTWO Trading" class="mx-auto md:mx-0"/> -->
                <h1 class="pb-3 text-3xl text-center text-yellow-700 poppin-bold md:text-left md:pb-0">KTWO Trading</h1>
            </div>
            <div class="w-full text-center md:text-right md:w-1/3">
                <a href="tel:+60122363652" class="text-xl text-yellow-700 md:text-2xl poppin-semi">
                    <span class="text-lg open-reg">Call Us At</span><br/>
                    <i class="fas fa-phone-alt"></i> 012-2363652
                </a>
            </div>
        </div>
    </div>

</template>

<script>
export default {

}
</script>

<style>

</style>